import React from 'react';
import PropTypes from 'prop-types';

const NumberInput = (props) => {
    const handleNumberInput = (e) => {
        const value = e.target.value;

        // Only update if the input is a valid number
        if (!isNaN(value)) {
            if (props.onChange) {
                props.onChange(Number(value));
            }
        }
    };

    return (
        <div className={`number-input-wrapper ${props.center ? 'number-input-wrapper--center' : ''}`}>
            <div className="form-item suffix-inside--wrapper">
                <label htmlFor={props.label + props.suffix} className="form-item__label">
                    {props.label}
                </label>
                <div className='suffix-inside-input'>

                    <input id={props.label + props.suffix} type="text" value={props.value} className="input__input"
                        onChange={handleNumberInput}
                    />
                    <span className="suffix-inside">{props.suffix}</span>
                </div>
            </div>
            <p className='assistive-text'>{props.assistiveText}</p>
        </div >
    );
};

NumberInput.props = {
    id: PropTypes.string,
    center: PropTypes.bool,
    suffix: PropTypes.string,
    assistiveText: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.element,
    onChange: PropTypes.func
};

export default NumberInput;
