import React from 'react';
import Icon from '../../../../../icon';
import PropTypes from 'prop-types';

const CalculatorTooltip = (props) => {

    return (
        <div className={`calculator-tooltip-icon--wrapper`} tabIndex={0}>
            <Icon icon={'information'} additionalClasses="calculator-result__item--icon calculator-result__item--icon-white"></Icon>
            <Icon icon={'information-black'} additionalClasses="calculator-result__item--icon calculator-result__item--icon-black"></Icon>
            <div className={'calculator-tooltip'}>

                <div className={`calculator-tooltip__content`}>
                    {props.content}
                    <div class="calculator-tooltip__arrow"></div>
                </div>
            </div>
        </div>
    );
};

CalculatorTooltip.propTypes = {
    content: PropTypes.string
};

export default CalculatorTooltip;
