import throttle from 'lodash/throttle';

const calculateStickyOffset = (selectors) => {
    let offset = 0;
    for (let selector of selectors) {
        const el = document.querySelector(selector);
        if (el) {
            offset += el.offsetHeight;
        }
    }
    return offset;
};

const StickyNavigation = (el) => {
    const height = el.offsetHeight;
    const stickyOffsetElements = ['.top-bar'];
    const stickyOffset = calculateStickyOffset(stickyOffsetElements);
    const topbarHeight = el.querySelector('.header__topbar').offsetHeight + stickyOffset;
    const container = el.querySelector('.header__container');
    let lastScrollTop = 0;

    const resetStickyNavigation = () => {
        el.classList.remove('header--sticky', 'header--sticky-animation');
        el.style.height = 'auto';
        container.style.transform = 'translateY(0px)';
    };

    const handleScroll = () => {
        if (el.classList.contains('header--small')) {
            resetStickyNavigation();
            return;
        }

        let currentScrollTop = document.documentElement.scrollTop;

        if (currentScrollTop > topbarHeight) {
            el.style.height = height + 'px';
            el.classList.add('header--sticky');

            if (currentScrollTop > lastScrollTop) {
                container.style.transform = 'translateY(' + -topbarHeight + 'px)';
            } else {
                container.style.transform = 'translateY(-' + stickyOffset + 'px)';
            }

            // Timeout and extra class needed for animation to be smootly.
            setTimeout(() => {
                el.classList.add('header--sticky-animation');
            }, 10);
        }

        if (currentScrollTop <= stickyOffset) {
            resetStickyNavigation();
        }

        lastScrollTop = currentScrollTop;
    };

    window.addEventListener('scroll', throttle(handleScroll, 10));
};

export default StickyNavigation;
