import React from 'react';
import { CalculationContext } from '../../../../context/CalculationContext';
import PropTypes from 'prop-types';
import Switch from '../../../../../switch/Switch';

const SwitchComponent = (props) => {
    return (
        <div className='additional-settings--switch-component'>

            <Switch
                onChange={props.onChange}
                checked={props.checked}
                label={props.label}
                id={props.id}
                disabled={props.disabled}
            />
            <span className='additional-settings--switch-description'>{props.description}</span>
        </div>
    );
};

const ProductAndServices = ({ props }) => {
    const calculationContext = React.useContext(CalculationContext);

    return (
        <div className='switch-layout'>
            <SwitchComponent
                onChange={calculationContext.setChillerHeatpump}
                checked={calculationContext.chillerHeatpump}
                label={props.translations.chillerHeatpumpLabel}
                id={props.translations.chillerHeatpumpLabel}
                description={props.translations.chillerHeatpumpDescription}
                disabled={!calculationContext.HasChillerOrHeatpump}
            />
            <SwitchComponent
                onChange={calculationContext.setHydronicOptimisation}
                checked={calculationContext.hydronicOptimisation}
                label={props.translations.hydronicOptimisationLabel}
                id={props.translations.hydronicOptimisationLabel}
                description={props.translations.hydronicOptimisationDescription}
                disabled={!calculationContext.chillerHeatpump}
            />
            <SwitchComponent
                onChange={calculationContext.setWaterflowOptimisation}
                checked={calculationContext.waterflowOptimisation}
                label={props.translations.waterflowOptimisationLabel}
                id={props.translations.waterflowOptimisationLabel}
                description={props.translations.waterflowOptimisationDescription}
                disabled={!calculationContext.chillerHeatpump}
            />
            <SwitchComponent
                onChange={calculationContext.setAirHandlingUnitWithBuiltInControls}
                checked={calculationContext.airHandlingUnitWithBuiltInControls}
                label={props.translations.airHandlingUnitWBuiltinControlsLabel}
                id={props.translations.airHandlingUnitWBuiltinControlsLabel}
                description={props.translations.airHandlingUnitWBuiltinControlsDescription}

            />
            <SwitchComponent
                onChange={calculationContext.setAHUWatertempOptimisation}
                checked={calculationContext.aHUWatertempOptimisation}
                label={props.translations.waterTemperatureOptimisationLabel}
                id={props.translations.waterTemperatureOptimisationLabel}
                description={props.translations.waterTemperatureOptimisationDescription}
                disabled={!calculationContext.chillerHeatpump || !calculationContext.airHandlingUnitWithBuiltInControls}
            />
            <SwitchComponent
                onChange={calculationContext.setDCVAirOptimisation}
                checked={calculationContext.dCVAirOptimisation}
                label={props.translations.dcvAirOptimisationLabel}
                id={props.translations.dcvAirOptimisationLabel}
                description={props.translations.dcvAirOptimisationDescription}

            />
            <SwitchComponent
                onChange={calculationContext.setDCVWatertempOptimisationRoom}
                checked={calculationContext.dCVWatertempOptimisationRoom}
                label={props.translations.waterTemperatureOptimisationRoomLabel}
                id={props.translations.waterTemperatureOptimisationRoomLabel}
                description={props.translations.waterTemperatureOptimisationRoomDescription}
                disabled={!calculationContext.chillerHeatpump || !calculationContext.dCVAirOptimisation}
            />
            <SwitchComponent
                onChange={calculationContext.setAnalyticsandVisualisation}
                checked={calculationContext.analyticsandVisualisation}
                label={props.translations.analyticsAndVisualisationLabel}
                id={props.translations.analyticsAndVisualisationLabel}
                description={props.translations.analyticsAndVisualisationDescription}

            />

        </div>
    );
};

ProductAndServices.props = {
    translations: PropTypes.any

};

export default ProductAndServices;
