import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../icon';
import ResultCard from './ResultCard';
import { calculationDataLayerPush } from '../../../../utils/dataLayer';
import { CalculationContext } from '../../../../context/CalculationContext';

const Result = (props) => {
    const calculationContext = React.useContext(CalculationContext);

    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Loader
    React.useEffect(() => {
        const duration = 1500; // 1.5 seconds
        const increment = 100; // counting from 0 to 100
        const intervalTime = duration / increment; // time per increment
        if (props.startCount) {
            setCount(0);
            setLoading(true);

            const interval = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount < 100) {
                        return prevCount + 1;
                    } else {
                        props.handleDialogPopup('result');
                        setLoading(false);
                        clearInterval(interval);
                        return prevCount;
                    }
                });
            }, intervalTime);

            return () => clearInterval(interval); // Cleanup interval on unmount
        }
    }, [props.startCount]);

    // Set success and error messages from translations
    React.useEffect(() => {
        calculationContext.setSendEmailSuccessMessage(props.translations.sendEmailSuccessMessage);
        calculationContext.setSendEmailErrorMessage(props.translations.sendEmailErrorMessage);
    }, []);

    return (
        <>
            {/* Result popup */}
            <dialog className="calculator__result-dialog">
                <div>
                    <h2>{count}%</h2>
                    <div className="calculator__result-dialog--label" >
                        <p>{props.translations.loaderDisclaimer}</p>
                    </div>
                </div>
            </dialog>

            {/* Result */}
            <div className={`calculator-result ${props.downloadPdfDisabled ? 'disabled' : ''}`} >

                {/* Download PDF popup */}
                <dialog className="calculator-result__dialog-pdf">
                    <div className="calculator-result__dialog-pdf--inner">
                        <h2 className='headline-3'>{props.heading}</h2>
                        <p>{props.preamble}</p>
                        {!calculationContext.sendEmailStatus
                            ? <>
                                <form>
                                    <div className="form-item  ">
                                        <label htmlFor="input-field" className="form-item__label">
                                            {props.translations.emailLabel}*{emailError && <span className="form-item__error-msg h-margin-left-1">{props.translations.emailErrorMessage}</span>}
                                        </label>
                                        <input id="input-field" type="email" autoComplete="email" required
                                            value={email}
                                            onChange={() => setEmail(event.target.value)} className="input__input" placeholder={props.translations.emailPlaceholder} />

                                    </div>
                                    <div className="form-item  ">
                                        <label htmlFor="input-field" className="form-item__label">
                                            {props.translations.companyLabel}
                                        </label>
                                        <input id="input-field" type="text" value={company} onChange={() => setCompany(event.target.value)} className="input__input" placeholder={props.translations.companyPlaceholder} />

                                    </div>
                                    <div className="form-item  ">
                                        <label htmlFor="input-field" className="form-item__label">
                                            {props.translations.phoneLabel}
                                        </label>
                                        <input id="input-field" type="text" autoComplete="tel" value={phone} onChange={() => setPhone(event.target.value)} className="input__input" placeholder={props.translations.phonePlaceholder} />

                                    </div>
                                </form>

                                <div>

                                    <button className="button  h-margin-top-2" onClick={() => {
                                        if (!emailPattern.test(email)) {
                                            setEmailError(true);
                                            return;
                                        }
                                        calculationContext.calculate('send', email, company, phone);
                                    }}>
                                        <span className="button__label">{props.translations.send}</span>
                                    </button>
                                </div>
                            </>
                            : <div className="calculator-result__dialog-pdf--success-message">{calculationContext.sendEmailStatus}</div>
                        }
                        <button className='calculator-result__dialog-pdf--close-button' autoFocus onClick={() => {
                            calculationDataLayerPush('calculation_saved', calculationContext.country, calculationContext.application, calculationContext.newBuilding, calculationContext.sizeOfBuilding);
                            props.handleDialogPopup('downloadPdf');
                            calculationContext.setSendEmailStatus('');
                        }}>
                            <Icon icon='close' additionalClasses="calculator-result__item--icon"></Icon>
                        </button>
                    </div>
                </dialog >

                <div id="calculator-results" className='calculator-result__card-wrapper'>
                    <ResultCard percentValue={loading ? 0 : calculationContext.EnergySavingsPercentage}
                        preamble={props.translations.energySavingsPreamble}
                        energySavings={props.translations.energySavingsLabel}
                        disabled={props.downloadPdfDisabled}
                    >
                        <div className="calculator-result__money-savings">{loading ? 0 : calculationContext.TotalEnergySavings.toLocaleString('sv-SE')} {props.translations.locKwhLabel}/{props.translations.yearLabel}</div>
                        <div className="calculator-result__money-savings calculator-result__thermal-energy">{props.translations.thermalSavingsLabel} {calculationContext.ThermalEnergySavings}%</div>
                        <div className="calculator-result__money-savings calculator-result__electric-energy">{props.translations.electricalSavingsLabel} {calculationContext.ElectricEnergySavings}%</div>

                    </ResultCard>
                    <ResultCard percentValue={loading ? 0 : calculationContext.Co2SavingsPercentage}
                        preamble={props.translations.reducedCo2EmissionsPreamble}
                        energySavings={props.translations.reducedCo2EmissionsLabel}
                        disabled={props.downloadPdfDisabled}
                    >
                        <div className="calculator-result__money-savings">{loading ? 0 : calculationContext.Co2Savings.toLocaleString('sv-SE')} {props.translations.kCo2eLabel}/{props.translations.yearLabel}</div>
                    </ResultCard>
                    <ResultCard percentValue={loading ? 0 : calculationContext.FinancialSavingsPercentage}
                        preamble={props.translations.financialSavingsPreamble}
                        energySavings={props.translations.financialSavingsLabel}
                        disabled={props.downloadPdfDisabled}
                    >
                        <div className="calculator-result__money-savings">{loading ? 0 : calculationContext.FinancialSavings.toLocaleString('sv-SE')} {calculationContext.currency}/{props.translations.yearLabel}</div>
                    </ResultCard>

                </div>

                <div className='calculator-result__additional-info'>
                    <div className='calculator-result__additional-info_heading'>{props.translations.estimatedEnergyUsage}</div>
                    <div className='calculator-result__additional-info_result'><span className='calculator-result__additional-info_value'>{calculationContext.NewEnergyConsumption.toLocaleString('sv-SE')}</span> {props.translations.locKwhLabel}/{props.translations.yearLabel}</div>

                </div>
                <div className='calculator-result__additional-info--button-wrapper'>

                    <div className='calculator-result__additional-settings calculator__button--primary'>
                        <button onClick={() => props.handleDialogPopup('additionalSettings')} disabled={props.downloadPdfDisabled} className="button button--rounded">{props.translations.additionalSettingsLabel}<Icon icon='filter' additionalClasses="calculator-result__item--icon" />
                        </button>
                    </div>

                    <div className='calculator-result__additional-settings  calculator__button--ghost'>
                        <button onClick={() => props.handleDialogPopup('downloadPdf')} disabled={props.downloadPdfDisabled} className="button button--rounded">{props.translations.saveResultsLabel}<Icon icon='mail' additionalClasses="calculator-result__item--icon" />
                        </button>
                    </div>
                </div>
                {/* Calculator Disclaimer */}
                <div className='calculator__disclaimer' dangerouslySetInnerHTML={{ __html: props.calculateDisclaimer }}></div>

            </div >
        </>
    );
};

Result.props = {
    translations: PropTypes.any,
    startCount: PropTypes.any,
    downloadPdfDisabled: PropTypes.bool,
    handleDialogPopup: PropTypes.func,
    calculateDisclaimer: PropTypes.string,
    heading: PropTypes.string,
    preamble: PropTypes.string,
    children: PropTypes.element
};

export default Result;
