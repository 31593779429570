import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../icon';
import Input from '../../../../../form/input/Input';
import Accordion from '../../../Accordion';
import ProductAndServices from './ProductsAndServices';
import { calculationDataLayerPush } from '../../../../utils/dataLayer';
import { CalculationContext } from '../../../../context/CalculationContext';
import AdvancedSettings from './AdvancedSettings';
import InitialParameters from '../InitialParameters/InitialParameters';

const AdditionalSettings = (props) => {
    const calculationContext = React.useContext(CalculationContext);

    const scrollToResult = () => {
        const resultHead = document.querySelector('#calculator-results');
        scrollTo(0, resultHead.offsetTop - 20);
    };

    return (
        <dialog className="calculator-result__dialog-additional-settings">
            <div className="calculator-result__dialog-additional-settings--inner">
                <section>
                    <h2 className='h-margin-bottom-5'>{props.translations.requiredSettingsLabel}</h2>
                    <InitialParameters translations={props.translations} countries={props.countries} applications={props.applications} />
                </section>

                <section>
                    <div className="calculator-result__dialog-additional-settings--primary-energy">
                        <div className='h-display-flex'>

                            <p className='primary-energy--title'>{props.translations.primaryEnergyTitle}</p>
                            <div className='h-display-flex'>
                                <p className='primary-energy--value'>{calculationContext.PrimaryEnergyNumber}</p>
                                <p className='primary-energy--suffix'>{props.translations.kwhSqmLabel} {props.translations.yearLabel}</p>
                            </div>
                            <div className='primary-energy--tooltip'>
                                <div className='calculator-tooltip-icon--wrapper calculator-tooltip-icon--wrapper-primary-energy' tabIndex={0}>
                                    <Icon icon={'information'} additionalClasses="calculator-result__item--icon calculator-result__item--icon-white"></Icon>
                                    <Icon icon={'information-black'} additionalClasses="calculator-result__item--icon calculator-result__item--icon-black"></Icon>
                                    <div className='calculator-tooltip--primary-energy'>

                                        <div className={`calculator-tooltip--primary-energy__content`}>
                                            {props.translations.primaryErrorEnergyTooltip}
                                            <div class="calculator-tooltip__arrow"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {calculationContext.IsOverLegalLimit && calculationContext.newBuilding &&
                            <p className="h-text-center primary-energy--error" >{props.translations.primaryEnergyError}</p>
                        }
                    </div>
                </section>

                <section className='h-margin-top-2'>
                    <h2 >{props.translations.additionalSettingsLabel}</h2>
                    <p>{props.translations.additionalSettingsPreamble}</p>
                    <Accordion open label={props.translations.calculationSettingsLabel}>
                        <div className='calculator__input-wrapper'>

                            {/* Select heating */}
                            <Input label={props.translations.heatingLabel} id='heatingTypes' onChange={calculationContext.setHeatingType}>
                                {props.heatingTypes.map((option, i) => <option key={`${i}-${option.value.name}`} defaultValue={option.value.name === calculationContext.heatingType} option={option.value.name}>{option.value.name}</option>)}
                            </Input>

                            {/* Select hot water */}
                            <Input label={props.translations.hotwaterTypesLabel} id='hotwaterTypes' onChange={calculationContext.setHotWaterProduction}>

                                {props.hotwaterTypes.map((option, i) => <option key={`${i}-${option.value.name}`} defaultValue={option.value.name === calculationContext.hotwaterProduction} option={option.value.name}>{option.value.name}</option>)}
                            </Input>

                            {/* Select cooling */}
                            <Input label={props.translations.coolingLabel} id='coolingTypes' onChange={calculationContext.setCoolingProduction}>
                                {props.coolingTypes.map((option, i) => <option key={`${i}-${option.value.name}`} defaultValue={option.value.name === calculationContext.coolingProduction} option={option.value.name}>{option.value.name}</option>)}
                            </Input>
                        </div>
                    </Accordion>

                    <Accordion open tooltipContent={props.translations.swegonProductsAndServicesTooltip} label={props.translations.swegonProductsAndServicesLabel}>
                        <ProductAndServices props={props} />
                    </Accordion>

                    <AdvancedSettings props={props} />

                </section>
                <button className='calculator-result__dialog-additional-settings--close-button' autoFocus onClick={() => props.handleDialogPopup('additionalSettings')}>
                    <Icon icon='close' additionalClasses="calculator-result__item--icon"></Icon>
                </button>
                <div className='additional-settings__buttons'>
                    <button className='button button--rounded button--ghost' onClick={() => props.handleDialogPopup('additionalSettings')}>{props.translations.cancel}</button>
                    <button className='button button--rounded' onClick={() => {
                        calculationDataLayerPush('additional_calculation_submit', calculationContext.country, calculationContext.application, calculationContext.newBuilding, calculationContext.sizeOfBuilding);
                        calculationContext.calculate('calculate');
                        props.handleDialogPopup('additionalSettings');
                        props.handleDialogPopup('result');
                        scrollToResult();
                    }}>{props.translations.calculateLabel}</button>
                </div>
            </div>
        </dialog>);
};

AdditionalSettings.props = {
    translations: PropTypes.any,
    countries: PropTypes.any,
    applications: PropTypes.any,
    handleDialogPopup: PropTypes.func
};

export default AdditionalSettings;
