import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../../form/input/Input';

import { CalculationContext } from '../../../../context/CalculationContext';
import NumberInput from '../components/NumberInput';

const InitialParameters = (props) => {
    const calculationContext = React.useContext(CalculationContext);

    return (
        <div className='calculator__input-wrapper'>

            {/* Select New building */}
            <div className="form-item form-item--select ">
                <label htmlFor={'newBuilding'} className="form-item__label">
                    {props.translations.buildingTypeLabel}
                </label>
                <select id={'newBuilding'} onChange={(e) => {
                    const isNewBuilding = e.target.value === props.translations.newBuildingLabel;
                    calculationContext.setNewBuilding(isNewBuilding);
                    calculationContext.setEnergyUsePerSquareMeter(isNewBuilding ? calculationContext.energyUseSqMetersNew : calculationContext.energyUseSqMetersExisting);
                }} className="select__select " name="Select" >

                    <option defaultValue={calculationContext.newBuilding === true} value={props.translations.newBuildingLabel}>{props.translations.newBuildingLabel}</option>
                    <option defaultValue={calculationContext.newBuilding === false} value={props.translations.existingBuildingLabel}>{props.translations.existingBuildingLabel}</option>
                </select>
            </div>

            {/* Select Construction Type */}
            <Input label={props.translations.applicationLabel} id='constructionType' onChange={calculationContext.setApplication}> {props.applications.map((option, i) => <option key={`${i}-${option.value.application}`} defaultValue={option.value.application === calculationContext.application} option={option.value.application}>{option.value.application}</option>)}</Input>

            {/* Select Energy Usage */}

            <NumberInput label={props.translations.energyUsePerSquareMeterLabel} onChange={calculationContext.setEnergyUsePerSquareMeter} value={calculationContext.energyUsePerSquareMeter} suffix={props.translations.kwhSqmLabel} />

            {/* Select Size in Square Meters */}
            <NumberInput label={props.translations.sizeInSqmLabel} onChange={calculationContext.setSizeOfBuilding} value={calculationContext.sizeOfBuilding} suffix={props.translations.squareMeter} />

        </div >
    );
};

InitialParameters.props = {
    translations: PropTypes.any,
    countries: PropTypes.any,
    applications: PropTypes.any
};

export default InitialParameters;
