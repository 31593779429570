import React from 'react';
import PropTypes from 'prop-types';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
import InitialParameters from '../InitialParameters/InitialParameters';
import { calculationDataLayerPush } from '../../../../utils/dataLayer';

import { CalculationContext } from '../../../../context/CalculationContext';

const CalculatorHeroBlock = ({ props, setDownloadPdfDisabled, handleDialogPopup }) => {
    const calculationContext = React.useContext(CalculationContext);

    return (
        <div className='calculator'>

            <LottieAnimation />

            <div className='calculator__banner-wrapper'>
                <div className='calculator__banner' role="banner">
                    <div role="doc-subtitle" className="tagline">{props.tagLine}</div>
                    <h1 className='calculator__heading'>{props.heading}</h1>
                </div>
                <div className="calculator-rte">
                    <div dangerouslySetInnerHTML={{ __html: props.preamble }}></div>
                </div>
            </div>

            {/* Initial parmeters */}
            <InitialParameters
                translations={props.translations}
                countries={props.countries}
                applications={props.applications}
            />

            {/* Calculate Button */}
            <div className='calculator__button'>
                <button className="button button--rounded calculator__calculate-button" onClick={() => {
                    calculationDataLayerPush('calculation_submit', calculationContext.country, calculationContext.application, calculationContext.newBuilding, calculationContext.sizeOfBuilding);
                    setDownloadPdfDisabled(false);
                    handleDialogPopup('result');
                    calculationContext.calculate('calculate');
                }
                }>
                    {props.translations.calculateLabel}
                </button>
            </div>

        </div>
    );
};

CalculatorHeroBlock.props = {
    props: PropTypes.any,
    setDownloadPdfDisabled: PropTypes.func,
    handleDialogPopup: PropTypes.func
};

export default CalculatorHeroBlock;
